import { Subtitle, Text, Spacer } from "../sidebar/contents/dynamic.components";

const ThankYou = () => {
    return (
        <div className="c-cytiva__thank-you">
            <Text text="Thank you for your interest in our ÄKTA process extended chromatography system." />
            <Spacer />
            <Text text="A Cytiva representative will contact you shortly." />
        </div>
    );
};
export default ThankYou;
