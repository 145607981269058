import React from "react";
import { useStore } from "src/store";
import SubLinkComponent from "./SubLink.component";

type SubLinkListProps = {};

const SubLinkList: React.FC<SubLinkListProps> = () => {
    const { activeNavLink, toggleOverlay } = useStore();

    const handleRequestInfo = () => {
        toggleOverlay("information");
    };

    return (
        <>
            <nav className="c-cytiva__sub">
                <ul className="c-cytiva__sub-list">
                    {activeNavLink.subLinks.map((subLink) => (
                        <React.Fragment key={subLink.id}>
                            <SubLinkComponent subLink={subLink} />
                        </React.Fragment>
                    ))}
                </ul>
            </nav>
            <button
                onClick={handleRequestInfo}
                className="button--fixwidth-large button button--large button--tertiary c-cytiva__sub-button-request"
            >
                Request more information
            </button>
        </>
    );
};

export default SubLinkList;
